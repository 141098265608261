import React from "react"


const Back = ({ title }) => {
 

  return (
   
      <div className='margin'></div>
   
  )
}

export default Back
