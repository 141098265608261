import React from 'react';
import './adminPanel.css';

const AdminDashboard = () => {
  return (
    <div className="admin-container">
      <h2>Admin Dashboard</h2>
      {/* Add your dashboard components here */}
    </div>
  );
};

export default AdminDashboard;
